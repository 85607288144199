@import "src/scss/variables";
@import "src/scss/flex";
@import "src/scss/mixins";
@import "src/scss/media_queries";
@import "src/scss/theme";

.container {
  width: 100%;
  // display: flex;
  // flex-direction: column;
  // align-items: center;
}

.file-upload-box {
  width: 100%;
  label {
    width: 100%;
  }
  svg {
    margin-right: 10px !important;
  }
}

.file-desc {
  font-size: 15px;
  font-weight: 500;
}

.file-success {
  font-size: 12px;
  font-weight: 500;
  max-width: 700px;
  width: fit-content;
  padding: 0.6rem 1rem !important;
  text-align: center;
}
.fileformat {
  h5 {
    font-size: 20px;
  }
  box-shadow: 1px 1px 12px #e0e0e0;
  border-radius: 3px;
  width: fit-content;
  padding: 15px 20px;
}

.format-para {
  font-size: 12px;
}
