@import "src/scss/variables";
@import "src/scss/flex";
@import "src/scss/mixins";
@import "src/scss/media_queries";
@import "src/scss/theme";

.reveal-keys {
  @include flexbox();
  @include justify-content(center);
  button {
    width: 100%;
    max-width: 340px;
    background-color: $greyWhite;
    border: unset;
    @include border-radius(8px);
    height: 42px;
    color: $themeGreen;
  }
}
.url {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 160px;
}

.actions {
  @include flexbox();
}
.actions-edit {
  margin-right: 6px;
  cursor: pointer;
}
.actions-plane {
  cursor: pointer;
}
