@import 'src/scss/variables';

.logout-container {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .description {
    text-align: center;
    line-height: normal;

    h1 {
      color: $success-light;
      margin-bottom: 0.5rem;
    }

    p {
      color: $dark;
      font-size: 0.9rem;
    }
  }
}
