@import 'src/scss/functions';
@import 'src/scss/theme';
@import 'src/scss/variables';
@import 'src/scss/media_queries';

.one{
  padding: toRem(10) toRem(15);
  border-bottom: 1px solid $greyWhite;
  display: flex;
  .header{
    font-size: toRem(14);
    font-family: $fontFamilySemiBold;
    margin-bottom: toRem(20);
  }
  .infoItem{
    font-size: toRem(14);
    margin-bottom: toRem(5);
    .left{
      margin-right: toRem(10);
    }
  }
  .content{
    flex-grow: 1;
  }
  // .buttonContainer{
  //   text-align: center;
  //   margin: toRem(15) 0;
  // }
  .button{
    margin-left: auto;
    display: inline-flex;
    align-items: end;
  }
  &.horizontal{
    .content{
      display: flex;
      .infoItem{
        margin-right: toRem(25);
      }
    }
  }
}

@include screen-below('sm'){
  .one{
    flex-direction: column;
    .button{
      margin-right: auto;
      margin-left: 0;
    }
  }
}