.app-loader {
  background-color: rgba(255, 255, 255, 0.75);
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  padding: 0 1rem;

  &.button {
    background-color: rgba(255, 255, 255, 0);
    border-radius: 0.25rem;
    justify-content: flex-start;
  }

  &.column {
    flex-direction: column;

    .loader-title {
      margin-top: 1rem;
    }
  }

  &.fixed {
    position: fixed;
  }
}

.app-loader > .loader,
.app-loader > .loader:after {
  border-radius: 50%;
  width: 7em;
  height: 7em;
}

.app-loader > .loader {
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 0.75em solid rgba(249, 195, 2, 1);
  border-right: 0.75em solid rgba(249, 195, 2, 0.25);
  border-bottom: 0.75em solid rgba(249, 195, 2, 0.25);
  border-left: 0.75em solid rgba(249, 195, 2, 0.25);
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}

.app-loader.button > .loader,
.app-loader.button > .loader:after {
  width: 2.5em;
  height: 2.5em;
}

.app-loader.button > .loader {
  border-width: 0.3rem;
  border-top-color: #ffffff;
  border-right-color: #ffffff86;
  border-bottom-color: #ffffff86;
  border-left-color: #ffffff86;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
