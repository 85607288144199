@import "src/scss/variables";
@import "src/scss/flex";
@import "src/scss/mixins";
@import "src/scss/media_queries";
@import "src/scss/theme";

.divider {
  border-top: 1px solid $lightGrey;
}

.logout-btn {
  outline: unset;
  border: unset;
  padding: 2px 15px;
  border-radius: 5px;
  cursor: pointer;
}
.header {
  @include flexbox();
  @include justify-content(space-between);
  padding: 10px 20px 10px 20px;
  color: $pureBlack;
  .header-left {
    font-family: opensans-semibold;
    font-size: $headingSectionThree;
  }
  .header-right {
    font-family: opensans-regular;
    @include flexbox();
    @include align-items(center);
    .sheet-btn {
      border-radius: 8px;
      flex-shrink: 0;
      color: #000;
      border-color: #000;
      text-transform: none;
      margin-right: 12px;
    }

    .merchant-img {
      width: 32px;
      height: 32px;
      margin-left: 20px;
      background-color: $greyWhite;
      @include border-radius(50%);
    }
  }
}

.footer {
  padding: 20px 0px;
  @include flexbox();
  @include justify-content(center);
 
}
.plus-btn {
  margin-bottom: -6px;
  margin-right: 20px;
}
.header_btn {
  color: #000;
  text-align: center;
  font-family: Open Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}