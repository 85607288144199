@import "src/scss/variables";
@import "src/scss/flex";
@import "src/scss/mixins";
@import "src/scss/media_queries";
@import "src/scss/theme";

.title {
  font-size: $headingSectionThree;
  color: $footerBlack;
  font-family: opensans-semibold;
  text-align: center;
  padding: 10px;
}
.content {
  padding: 0px 25px;
}
.footer {
  @include flexbox();
  @include justify-content(center);
  padding: 10px 0px 30px 0px;
  button {
    width: 100%;
    max-width: 365px;
    text-align: center;
    @include flexbox();
    @include justify-content(center);
  }
}

.link-input {
  position: fixed;
  width: 10px;
  height: 10px;
  border: unset;
  background-color: transparent;
  z-index: -9999;
  left: 0px;
  top: 0px;
}

.link {
  text-align: center;
  a {
    color: green;
    font-size: $headingSection;
    font-family: opensans-semibold;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    display: -webkit-box;
    line-height: 1.5em;
    max-height: 3em;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
  }
  margin-bottom: 10px;
}

.copy-link {
  font-family: opensans-regular;
  color: $footerBlack;
  font-size: $textParagraphThree;
  @include flexbox();
  @include align-items(center);
  @include justify-content(center);
  img {
    margin-right: 5px;
  }
}
