@import "src/scss/variables";
@import "src/scss/theme";
@import "src/scss/functions";

.inputLabelContainer {
  margin-bottom: 0.2rem;
}
.textFieldContainer {
  display: flex;
  align-items: center;
}
.prefix {
  margin-right: toRem(10);
  display: inline-block;
}
.suffix {
  display: inline-block;
  margin-left: toRem(10);
}

.text-input-one {
  margin-bottom: 2px;
}
