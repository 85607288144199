.empty-table-content{
  height: 200px;
  flex-shrink: 0;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}

.collapsed-cell-container {
  align-items: center;
  justify-content: center;
}

.table-header-cell {
  font-weight: 600
}