@import "src/scss/variables";
@import "src/scss/flex";
@import "src/scss/mixins";
@import "src/scss/media_queries";
@import "src/scss/theme";

.search-box {
    display: flex;
    border-top-color: black;
    align-items: center;
    height: 3vh;
    padding-top: 2rem;
    .filter-btn {
      background-color: ButtonHighlight;
      padding-left: 1.2rem;
      min-width: 40px;
      position: relative;
      display: flex;
      align-items: center;
      margin-bottom: 0.9rem;
      margin-left: 0.8rem;
      width: 1.0rem;
      height: 2.0rem;
    }
  }

.search-input-box {
    position: relative;
    .searchbar {
      width: 98%;
      border-radius: 5px;
      background-color: #F3F3F3;
    }
}
.filter-input-box{
  position: relative;
  cursor: pointer;
}
.filter{
  width: 100%;
  border-radius: 5px;
  background-color: #F3F3F3;
  color: #878789;
}
.footer-btn,.pagination-container {
  display: flex;
  gap: 10px;
  justify-content: right;
  padding: 15px;
  position: fixed;
  align-items: center;
  bottom: 0;
  right: 0;
  .plus-btn {
    background-color: #FFF;
}
  .uploadFile-btn {
    border-radius: 8px;
    background: #000;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
    display: flex;
    width: 143px;
    justify-content: center;
    align-items: center;
    background-color: #000000;
    text-transform: none;
  }
  .uploadFile-btn:hover{
    background-color: #000000;
  }
}


.card-main {
  width: 226px;
  .card-content{
    display: flex;
    flex-direction: column;
  }
}


// Pagination style
.paginationstyle{
  display: flex;
  justify-content: center;
  align-items: center;;
}

// filterside style
.filterside{
  position: absolute;
  border: 1px solid black;
  width: 40%;
  top: 25%;
  left: 50%;
  color: #000000;
}
