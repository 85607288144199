@import 'src/scss/variables';
@import 'src/scss/flex';
@import 'src/scss/mixins';
@import 'src/scss/media_queries';
@import 'src/scss/theme';

.container {
  display: flex;
  flex-direction: column;

  .label {
    font-family: Avenir-Regular;
    font-size: 14px;
  }

  .input {
    border: 1px solid #E5E5E5;
    border-radius: 0.45rem;
    font-size: 14px;
    padding: 0.25rem 0.5rem;
    color: $blackTwo;
  }
}