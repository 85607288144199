.screen {
  height: 100%;
  width: 100%;
  position: absolute;

  .screen-container {
    height: calc(100% - 85px);
    flex-direction: column;
    display: flex;
    margin-bottom: 1rem;
  }

  .screen-footer {
    display: flex;
    justify-content: center;
    align-items: center;

    .powered-by {
      font-size: 0.75rem;
    }
    .footer-logo {
      height: 15px;
      margin-left: 5px;
      position: relative;
      top: 0px;
    }
  }
}
