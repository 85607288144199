@import "src/scss/variables";
@import "src/scss/flex";
@import "src/scss/mixins";
@import "src/scss/media_queries";
@import "src/scss/theme";

.label {
  font-family: Avenir-Regular !important;
  font-size: 14px !important;
  font-weight: unset !important;
}

.select {
  border: 1px solid #e5e5e5 !important;
  border-radius: 0.45rem !important;
  font-size: 14px !important;
  padding: 0.25rem 0.5rem !important;
  color: $blackTwo !important;
  margin-top: -6px !important;
}

.table-data {
  height: calc(100vh - 255px);
  overflow: auto;
}
.search-box {
  padding: 10px 20px;
}
.search-input-box {
  position: relative;
  input {
    padding-right: 50px;
  }
  .search-icon {
    position: absolute;
    right: 10px;
    top: 15px;
    width: 40px;
    text-align: center;
  }
}

.divider {
  border-top: 1px solid $lightGrey;
}
.header {
  @include flexbox();
  @include justify-content(space-between);
  padding: 10px 20px 10px 20px;
  color: $pureBlack;
  .header-left {
    font-family: opensans-semibold;
    font-size: $headingSectionThree;
  }
  .header-right {
    font-family: opensans-regular;
    @include flexbox();
    @include align-items(center);
    .merchant-img {
      width: 32px;
      height: 32px;
      margin-left: 20px;
      background-color: $greyWhite;
      @include border-radius(50%);
    }
  }
}

.footer {
  padding: 20px 0px;
  @include flexbox();
  @include justify-content(center);
  a {
    text-decoration: none;
  }
}
.plus-btn {
  margin-bottom: -6px;
  margin-right: 20px;
}
