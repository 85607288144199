$grid-breakpoints: (
  xm: 0,
  sm: 576px,
  // for-phone-up-sm
  md: 768px,
  // for-phone-up
  lg: 992px,
  // for-tablet-up
  xl: 1025px,
  // for-tablet-landscape-up
  xxl: 1200px,
  // for-tablet-landscape-up ipad
  xxxl: 1367px,
  // for-desktop-up
  large: 1600px,
  // for-desktop-up
  xlarge: 1920px,
  // for-big-screens-up
);

//

//  Media Query All screen
@mixin screen-up($breakpoint) {
  // If the breakpoint exists in the map.
  @if map-has-key($grid-breakpoints, $breakpoint) {
    // Get the breakpoint value.
    $breakpoint-value: map-get($grid-breakpoints, $breakpoint);
    // Write the media query.
    @media screen and (min-width: ($breakpoint-value)) {
      @content;
    }
  }
}

@mixin screen-below($breakpoints) {
  // If the breakpoint exists in the map.
  @if map-has-key($grid-breakpoints, $breakpoints) {
    // Get the breakpoint value.
    $breakpoint-value: map-get($grid-breakpoints, $breakpoints);
    // Write the media query.
    @media screen and (max-width: ($breakpoint-value - 1)) {
      @content;
    }
  }
}

/* Breakpoint sizes */

// min max meedia query minxing
@mixin respond-between($lower, $upper) {
  // If both the lower and upper breakpoints exist in the map.
  @if map-has-key($grid-breakpoints, $lower) and map-has-key($grid-breakpoints, $upper) {
    // Get the lower and upper grid-breakpoints.
    $lower-breakpoint: map-get($grid-breakpoints, $lower);
    $upper-breakpoint: map-get($grid-breakpoints, $upper);

    // Write the media query.
    @media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint - 1)) {
      @content;
    }

    // If one or both of the grid-breakpoints don't exist.
  } @else {
    // If lower breakpoint is invalid.
    @if (map-has-key($grid-breakpoints, $lower) == false) {
      // Log a warning.
      @warn 'Your lower breakpoint was invalid: #{$lower}.';
    }

    // If upper breakpoint is invalid.
    @if (map-has-key($grid-breakpoints, $upper) == false) {
      // Log a warning.
      @warn 'Your upper breakpoint was invalid: #{$upper}.';
    }
  }
}
