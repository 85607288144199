@import 'src/scss/theme';

.section{
  font-family: $fontFamilyRegular;
  font-size: $textSection;
  color: $textColor;
}

.p1{
  font-family: $fontFamilySemiBold;
  font-size: $textParagraphOne;
  color: $textColor;
}

.p2{
  font-family: $fontFamilyRegular;
  font-size: $textParagraphTwo;
  color: $textColor;
}

.finePrint{
  font-family: $fontFamilySemiBold;
  font-size: $textFinePrint;
  color: $textColor;
}