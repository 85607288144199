@import 'src/scss/functions';
@import 'src/scss/theme';
@import 'src/scss/variables';

@mixin commonStyles(){
  min-width: toRem(150);
  min-height: toRem(35);
  text-align: center;
  border: none;
  border-radius: toRem(8);
}

.one{
  @include commonStyles();
  background-color: $savvyYellow;
  font-family: $fontFamilySemiBold;
  font-size: toRem(14);
}

.two{
  @include commonStyles();
  background-color: $promptGrey;
  font-family: $fontFamilySemiBold;
  font-size: toRem(14);
}