.box-field { 
    margin-top: 20px;
    margin-bottom: 20px;
 }

.next-btn {
   background-color: black !important;
   display: flex;
   width: 60%;
   top: 24rem;
   left: 18%; 
   border-radius: 10px !important;
   height: 45px;
   text-transform: none;
}

.add-reminder-btn {
    display: flex;
    position: absolute;
    width: 60%;
    top: 12rem;
    left: 20%;
    background-color: black !important;
    font-size: large !important;
    text-transform: none !important;
}
.reminder-box {
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    padding: 1rem;
    border-radius: 15px;
    border: 1px solid #C3C3C3;
    border-style: dashed;
}
.close-btn {
    display: flex;
    justify-content: flex-end;
    padding-top: 1rem;
    padding-right: 1rem;
}
.reminder-day {
    width: 28%;
    padding: 0;
    font-size: 25px;
}