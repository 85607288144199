@import "src/scss/variables";
@import "src/scss/flex";
@import "src/scss/mixins";
@import "src/scss/media_queries";
@import "src/scss/theme";

.developers {
  font-family: opensans-regular;
}

.divider {
  border-top: 1px solid $lightGrey;
}
.header {
  color: red;
  @include flexbox();
  @include justify-content(space-between);
  padding: 10px 20px 10px 20px;
  color: $pureBlack;
  .header-left {
    font-family: opensans-semibold;
    font-size: $headingSectionThree;
  }
  .header-right {
    font-family: opensans-regular;
    @include flexbox();
    @include align-items(center);
    .merchant-img {
      width: 32px;
      height: 32px;
      margin-left: 20px;
      background-color: $greyWhite;
      @include border-radius(50%);
    }
  }
}

.api-key-box {
  @include flexbox();
  @include align-items(center);
  font-size: $textParagraphOne;
  padding: 6px 10px 14px 22px;
  .api-key-title {
    margin-top: 7px;
    font-size: $headingSectionThree;
    margin-right: 30px;
  }
  .api-key-desc {
    margin-top: 7px;
  }
}

.content {
  background-color: $greyBlackThree;
  @include border-radius(0px 0px 8px 8px);
  min-height: calc(100vh - 168px);
  height: 100%;
  @include flexbox();
  @include align-items(center);
  @include justify-content(center);
  width: 100%;
  padding: 40px 0px;
}

.content-box-cover {
  @include flexbox();
  @include align-items(center);
  @include justify-content(center);
  width: 100%;
  color: $pureWhite;
}
.content-box {
  background-color: $footerBlack;
  @include box-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  @include border-radius(8px);
  border: 1px solid $pureWhite;
  width: 100%;
  max-width: 250px;
  min-height: 284px;
  font-size: $headingSectionThree;
  text-align: center;
  margin-bottom: 20px;
  .content-title {
    font-family: opensans-medium;
    padding: 20px 0px 10px 0px;
  }
  .content-desc {
    font-family: opensans-regular;
    margin-bottom: 10px;
  }
  .content-data {
    min-height: 110px;
  }
  .postman-run-btn {
    height: 43px;
    min-width: 180px;
    background-color: rgba($pureWhite, 0.2);
    color: $pureWhite;
    @include border-radius(8px);
    outline: unset;
    border: unset;
    img {
      margin-right: 10px;
      margin-top: -2px;
    }
  }
  .footer-btn {
    height: 36px;
    background-color: $footerBlack;
    @include border-radius(8px);
    color: $pureWhite;
    border: 1px solid $pureWhite;
    padding: 0px 10px;
    min-width: 150px;
  }

  .content-number {
    font-size: 105px;
    line-height: 97px;
  }
  .content-note {
    font-size: $textFinePrint;
    margin-bottom: 22px;
  }
}
