@import "src/scss/variables";
@import "src/scss/flex";
@import "src/scss/mixins";
@import "src/scss/media_queries";
@import "src/scss/theme";

.title {
  font-size: $headingSectionThree;
  color: $footerBlack;
  font-family: opensans-semibold;
  text-align: center;
  padding: 10px;
}

.footer {
  @include flexbox();
  @include justify-content(center);
  padding: 80px 0px 30px 0px;
  button {
    width: 100%;
    max-width: 365px;
    text-align: center;
    @include flexbox();
    @include justify-content(center);
  }
}

.divider {
  border-top: 1px solid $lightGrey;
  margin-top: 16px;
  margin-bottom: 16px;
}

.fund-amount {
  position: relative;
}
.add-fund {
  height: 45px;
  position: relative;
}
.add-btn,
.remove-btn {
  background-color: #000000;
  color: #ffff;
  width: 40px;
  height: 40px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 26px;
  cursor: pointer;
}
.btn-wraper {
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
}

.fundname-error {
  color: red;
  font-size: 0.8rem;
  bottom: 0px;
}
.fundlable {
  font-size: 1.125rem;
}

.table-wrap {
  background: #f3f3f3;
  border-radius: 10px;
  margin-bottom: 14px;
  border: 1px solid #f3f3f3;
}
.tb-head {
  background: #c4eddc;
  border: 2px solid #c4eddc;
}
.tb-heading,
.tb-defin {
  border: 1.2px solid #bebebe;
  text-align: center;
}
.tb-heading {
  vertical-align: baseline;
}
.tb-defin:first-child {
  border-left: 0px;
}
.tb-defin:last-child {
  border-right: 0px;
}

.tb-defin:first-child,
.tb-heading:first-child {
  text-align: left;
}
.tb-heading {
  font-weight: 400;
}
.tb-head-nav {
  border: 0px;
  display: flex;
  flex-direction: column;
}

.table-btn {
  height: 40px;
  width: 30%;
  padding: 7px 13px;
  margin-top: 10px;
  background: #c5c5c5;
  margin-right: 10px;
  position: relative;
  border: 0px;
  border-radius: 10px;
  outline: 0px;
  font-weight: 400;
  display: flex;
  display: inline-table;
  align-items: center;
  text-align: left;
  justify-content: space-between;
}

.table-btn::after {
  position: absolute;
  content: "";
  bottom: -10px;
  left: 0px;
  right: 0px;
  width: 100%;
  height: 10px;
  background: #f3f3f3;
}
.table-title {
  color: #1c1c1f;
  margin: 0.2rem;
}

.check-box-content {
  padding-left: 30px;
}
.check-box {
  display: flex;
  align-items: self-end;
  margin-bottom: 10px;
  cursor: pointer;
  .check-label {
    font-size: $textParagraphThree;
    font-weight: 600;
    margin-left: 10px;
    margin-bottom: 5px;
  }
}

.reactangle-box {
  position: relative;
  .reactangle-box-tick {
    position: absolute;
    left: 3px;
    top: 5px;
  }
}
