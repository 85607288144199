@import 'src/scss/functions';
@import 'src/scss/theme';

.inputLabelContainer{
  label{
    margin-bottom: toRem(7);
  }
}
.radioButtonsContainer {
  list-style-type: none;
  // margin-top: 0.6rem;
  padding: 0;
  display: flex;
  li{
    margin-right: toRem(25);
    display: flex;
    position: relative;
  }
  input[type='radio'] {
    opacity: 0;
    z-index: 100;
    position: absolute;
    top: 0;
    left: 0;
  }
  input[type='radio']:checked + label .checkbox .icon{
    display: block;
  }
  label{
    z-index: 90;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .checkbox{
    border: 1px solid $lightGrey;
    border-radius: toRem(8);
    height: toRem(48);
    width: toRem(40);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: toRem(10);
    .icon{
      display: none;
    }
  }
}