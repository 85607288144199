@import "src/scss/functions";
@import "src/scss/theme";
@import "src/scss/variables";
@import "src/scss/flex";
@import "src/scss/mixins";

@mixin commonStyles() {
  text-align: center;
  border: none;
  height: 41px;
  @include border-radius(8px);
  padding: 10px 20px;
  @include flexbox();
  font-size: $headingSectionThree;

  @include align-items(center);
}

.main-btn {
  &:disabled {
    background-color: rgba($pureBlack, 0.5);
  }
}

.one {
  @include commonStyles();
  background-color: $pureBlack;
  font-family: opensans-medium;
  color: $pureWhite;
  cursor: pointer;
}

.two {
  @include commonStyles();
  background-color: $promptGrey;
  font-family: opensans-medium;
  font-size: toRem(14);
}
.success {
  background-color: $themeGreen;
}

.h-small {
  height: 30px;
  font-size: 13px;
}
